.footer{
  margin-top: 50px;
  text-align: center;
  background: #222;
  /*margin-top: 100%;*/
  height: 180px;
  padding:30px;
  background: linear-gradient(-20deg,#0fbed1b4 0%,#3affce 95%),#232323;
  }
  .footer .block
  {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex; 
    width: 90%;
  }
  @media only screen and (max-width: 600px) {
    img {
      max-width: 60px;
    }
  }
  
  .footer p{
    
    /*border-bottom: 1px solid #666;*/
      color: #eee;
      text-align:center;
      font-size: 20px;
      text-decoration: none;
      /*background: #21cfee*/
  
  }