.content
{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    /*flex-wrap: ;*/
    justify-content: center;  
    width: 90%;    
    background: white;
   /*flex-direction: row-reverse;*/
   /* flex-direction: ;*/

}