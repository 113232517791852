
.body
{
    flex-grow: 1;

}
.hide
{ 
    visibility : hidden; 
    width: 1px; 

}
.show
{
    position: fixed;
    top:65%;
    left:93%;
    visibility : visible;
    animation: ani 2s forwards;
    cursor: pointer;

}
@media only screen and (max-width: 700px) {
    .contentTop {
        width: 100%;
    }
    .show
    {
        width: 30px;
    }
  }